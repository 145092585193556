import * as React from "react"
import Layout from "../../components/layout"
import { graphql, Link } from "gatsby"
import Seo from "../../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import InstagramFeed from "../../components/instagramFeed"

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Blog" />

      <div className="bg-white">
        <div aria-hidden="true" className="relative">

          <StaticImage src="../../images/sincerely-media-ylveRpZ8L1s-unsplash.jpg" alt="Blog für Siebträger, Vollautomat, Filterkaffee und Mokka Kanne Bialetti Zubereritung, Kaffeebohnen"
                       className="w-full h-96 object-center object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-white" />
        </div>

        <div className="relative -mt-12 max-w-7xl mx-auto pb-16 px-4 sm:pb-24 sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center lg:max-w-4xl">
            <h2 className="text-6xl font-extrabold tracking-tight text-caffeinity-green-dark sm:text-4xl">Caffeinity Blog</h2>
            <p className="mt-4 text-gray-600">
              Wissenswertes, interessantes sowie Tipps & Tricks rund um das Thema Kaffee und Kaffeebohnen sowie
              Kaffeemaschinen jeglicher Art und Zubehör.
            </p>
          </div>
        </div>
      </div>


      <div className="relative bg-caffeinity-pink 8 pb-20 px-4 sm:px-6 lg:pt-16 lg:pb-28 lg:px-8">
        <div className="relative max-w-7xl mx-auto">

          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">

            {
              data.allMdx.nodes.map(node => (

                <div key={node.id} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <Link to={`/blog/${node.slug}`}>
                      <GatsbyImage
                        image={getImage(node.frontmatter.hero_image)}
                        alt={node.frontmatter.hero_image_alt}
                        className="lg:h-48 w-full h-28"
                      />
                    </Link>
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-caffeinity-green-light">
                        <Link to={`/blog/${node.slug}`} className="hover:underline">
                          {node.frontmatter.category}
                        </Link>
                      </p>
                      <Link to={`/blog/${node.slug}`} className="block mt-2">
                        <p className="text-xl font-semibold text-caffeinity-green-dark">{node.frontmatter.title}</p>
                        <p className="mt-3 text-base text-gray-600">{node.frontmatter.description}</p>
                        <div className="btn-primary mt-8">Zum Artikel</div>
                      </Link>
                    </div>
                    <div className="mt-6 flex items-center">
                      <div className="flex-shrink-0">
                        <Link to={`/blog/${node.slug}`} >
                          <span className="sr-only">{node.frontmatter.author}</span>
                          <StaticImage src="../../images/portrait/portrait_regina_kelis.png"
                                       alt="Portrait Dennis M. Binder von Caffeinity"
                                       className="h-10 w-10"
                                       imgClassName="rounded-full"
                          />
                        </Link>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-caffeinity-green-light">
                          <Link to={`/blog/${node.slug}`}  className="hover:underline">
                            {node.frontmatter.author}
                          </Link>
                        </p>
                        <div className="flex space-x-1 text-sm text-gray-500">
                          <time dateTime={node.frontmatter.date}>{node.frontmatter.date}</time>
                          <span aria-hidden="true">&middot;</span>
                          <span>{node.frontmatter.readingTime} read</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              ))
            }
          </div>
        </div>
      </div>

      <InstagramFeed/>

    </Layout>
  )
}

export const query = graphql`
query MyBlogOverviewQuery{
  allMdx(sort: {fields: frontmatter___date, order: DESC}) {
    nodes {
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        title
        category
        description
        author
        readingTime
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        hero_image_alt
      }
      id
      slug
    }
  }
}

`

export default BlogPage