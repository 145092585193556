import React from "react"
import ResponsiveEmbed from "react-responsive-embed"

export default function InstagramFeed() {

  return (

    <div className="relative pt-16">

      <div className="max-w-6xl mx-auto text-center px-4 sm:px-6 lg:px-8 ">

        <h1 className="flex-1 text-center text-caffeinity-green-light">Bleibe auf dem Laufenden...</h1>
        <p className="flex-1 text-center pb-24 text-caffeinity-green-dark">
          Auf Instagram teilen wir spannende Fakten rund um das Thema Kaffee. Wir verraten dir Tipps und Tricks für
          die Zubereitung und wie du mehr Geschmack und Genuss aus deinem Kaffee holen kannst. Folge uns um Nichts zu
          verpassen.</p>

        <script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script>
        <ResponsiveEmbed src="https://cdn.lightwidget.com/widgets/5a1239fa325859f0880f82fa243dc2d8.html" ratio="3:4"
                         title="Caffeinity Instagramm Account" scrolling="no" allowtransparency="true"
                         class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;padding-bottom:100%" />
      </div>
    </div>
  )
}
